import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {alpha, Box, Button, Chip, Fab, Grow, LinearProgress, Snackbar, Stack, Tab, Tabs} from "@mui/material";
import {DataGridPro, GridColumnHeaderFilterIconButton, itIT} from '@mui/x-data-grid-pro'
import UserService from "../../services/user.service";
import {Alert} from "@mui/lab";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import useAxios from "axios-hooks";
import SettingsService, {forms} from "../../services/settings.service";
import _, {each, filter, find, isEmpty} from 'lodash'
import {Archive, CheckCircle, Clear, FiberManualRecord, PersonAdd, QueryStats, Refresh} from "@mui/icons-material";
import {API_URL} from "../../config";
import axios from "axios";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomPageHeader from "../../components/CustomPageHeader";
import CustomToggleGraph from "./CustomToggleGraph";
import CustomDashboardGraph from "../../components/charts/CustomDashboardGraph";
import {GlobalContext} from "../../state/global";
import ColumnsService from "../../services/columns.service";
import {customTheme} from "../../theme/customTheme";
import CustomTooltip from "../../components/CustomTooltip";
import AddUserDialog from "./AddUserDialog";
import ArchivedUsersDialog from "./ArchivedUsersDialog";
import MultipleSelection from "./MultipleSelection";
import {QrCodeAppCheckin} from "../Login";
import FormCompletedCustomChip from "./FormCompletedCustomChip";
import {auth} from "../../firebase/clientApp";
import {domandeColumns, parseMultiLineTextBox} from "../../services/utils";
import IconButton from "@mui/material/IconButton";

const Iscrizioni = ({readOnly}) => {
    let navigate = useNavigate()

    const [globalState,] = useContext(GlobalContext)
    const {settings, dataStructures} = globalState

    const [pageSize, setPageSize] = useState(10)
    const [addUserDialogState, setAddUserDialogState] = useState({open: false})

    const _forms = useMemo(() => {
        if (!settings || !dataStructures)
            return null
        return SettingsService.getForms(settings, dataStructures, readOnly)
    }, [settings, dataStructures])

    const [openArchivedDialog, setOpenArchivedDialog] = useState(false)

    const [activeForm, setActiveForm] = React.useState(null);
    const handleChangeActiveTab = (event, newValue) => {
        if (newValue === _forms.length)
            setOpenArchivedDialog(true)
        else
            setActiveForm(_forms[newValue]);
    };

    useEffect(() => {
        if (_forms) {
            setActiveForm(_forms[0])
        }
    }, [_forms])

    const [allUsersFlag, setAllUsersFlag] = useState(true)
    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({archived: false, all: allUsersFlag}), {method: "GET", useCache: false, manual: false}
    )

    const users = useMemo(() => {
        if (!allUsers || !_forms)
            return null
        else return allUsers
    }, [allUsers, _forms])

    const usersPerForm = useMemo(() => {
        if (!users)
            return {}

        const res = []

        each(_forms, (form) => {
            res[form.id] = _.orderBy(_.filter(users, function (o) {
                    return true//o[form.id] === true || !!o[form.id] || !!o[form.timelineField]
                }),
                [form.id, form.id + '._seconds'], ['asc', 'desc'])
            if (form.id === forms.CHECKIN.id) {
                res[forms.CHECKIN.id] = _.orderBy(_.filter(users, ['partecipazione', 'partecipo']), ['checkin', 'checkedInAt._seconds'], ['asc', 'desc'])
            }
        })

        return res
    }, [users])

    const [filteredUsers, reports] = useMemo(() => {
        if (!usersPerForm || !activeForm)
            return [[], null]

        const _filteredUsers = usersPerForm[activeForm.id]

        let _reports = {}

        if (forms.CHECKIN.id === activeForm.id) {
            _reports = {
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'partecipo']).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
                'Felpe Ritirate': filter(_filteredUsers, function (o) {
                    return !!o.ritiroFelpa
                }).length,
                'Foto Scattate': filter(_filteredUsers, function (o) {
                    return !!o.ritiroFoto
                }).length,
            }
        } else if (activeForm.id === 'dataRichiesta') {
            _reports = {
                'Richieste in elaborazione': _.filter(_filteredUsers, ['esitoRichiesta', 'processing']).length,
                'Richieste approvate': _.filter(_filteredUsers, ['esitoRichiesta', 'success']).length,
                'Richieste rifiutate': _.filter(_filteredUsers, ['esitoRichiesta', 'denied']).length,
                'Richieste interne': _.filter(_filteredUsers, ['esitoRichiesta', 'interna']).length,
            }
        } else {
            _reports = {
                'Utenti totali': _filteredUsers?.length,
                'Partecipanti fisici': _.filter(_filteredUsers, ['partecipazione', 'partecipo']).length,
                'Non partecipanti': filter(_filteredUsers, ['partecipazione', 'nonPartecipo']).length,
                'Pending': filter(_filteredUsers, function (o) {
                    return !o.partecipazione
                }).length,
                'Check-in effetttuati': _.filter(_filteredUsers, ['checkin', true]).length,
                'Felpe Ritirate': filter(_filteredUsers, function (o) {
                    return !!o.ritiroFelpa
                }).length,
                'Foto Scattate': filter(_filteredUsers, function (o) {
                    return !!o.ritiroFoto
                }).length,
            }
        }

        //console.log("_filteredUsers:", _filteredUsers)

        return [_filteredUsers, _reports]
    }, [usersPerForm, activeForm])

    const [{data: config, loading: loadingConfig}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false
    })

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

    useEffect(() => {
        if (config && config[activeForm?.id] && globalState.authState.uid)
            setColumnVisibilityModel(config[activeForm?.id][globalState.authState.uid]?.defaultVisibilityModel)
    }, [config, activeForm, globalState])

    const checkinUser = async (id) => {
        //console.log("id:", id)
        await axios.get(API_URL + "participantsFisici/" + id + "/checkin" + `/${globalState?.checkinEvent}`)
            .then(() => fetchUsers())
            .catch(err => {
                //console.log("err:", err)
                setError(err.response?.data?.error || "Errore")
            })
    };

    const resetCheckInUser = async (id) => {
        await axios.get(API_URL + "participantsFisici/" + id + "/reset")
            .then(() => fetchUsers())
            .catch(err => setError(err.response))
    };

    const columns = useMemo(() => {
        if (!globalState.dataStructures)
            return []

        if (activeForm?.id === forms.CHECKIN.id) {
            const checkInCols = [{
                field: "action",
                headerName: "Action",
                sortable: false,
                flex: 1,
                minWidth: 150,
                renderCell: (params) => {
                    const onClick = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await checkinUser(params.id)
                    };
                    const onClickReset = async (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        await resetCheckInUser(params.id)
                    };

                    return !params.row.checkin ?
                        <Button variant={"contained"}
                                endIcon={<CheckCircle sx={{fontSize: '2.5rem'}}/>}
                                onClick={onClick}>Check-in</Button> :
                        <Button variant={"outlined"}
                                endIcon={<Clear sx={{color: 'red', fontSize: '2.5rem'}}/>}
                                onClick={onClickReset}>Reset Check-in</Button>;
                }
            }]

            return ColumnsService
                .getColumns(globalState.dataStructures[activeForm?.id], globalState?.questions || [], globalState?.questionsAtt2 || [], fetchUsers)
                .concat(checkInCols)
        }

        return [{
            field: activeForm?.id,
            headerName: activeForm?.id !== 'dataRichiesta' ? "Data Completamento" : "Data Richiesta",
            sortable: true,
            //flex: 1.1,
            minWidth: 150,
            type: 'dateTime',
            hideable: false,
            valueGetter: (params) => {
                return params.value?._seconds ?
                    new Date(params.value?._seconds * 1000)
                    : params.value
            },
            valueFormatter: (params) => {
                return (params.value?._seconds ?
                    new Date((params.value?._seconds * 1000)).toLocaleString('it-IT').replace(',', ' -')
                    : params.value ?
                        new Date(params.value).toLocaleString('it-IT').replace(',', ' -')
                        : (activeForm?.id !== 'dataRichiesta' ? "Non completato" : "Nessuna Richiesta"));
            },
            renderCell: (params) =>
                <FormCompletedCustomChip completionField={params.value} richiesta={activeForm?.id === 'dataRichiesta'}/>
        }]
            .concat(ColumnsService
                .getColumns(globalState.dataStructures[activeForm?.id], globalState?.questions || [], globalState?.questionsAtt2 || [], fetchUsers)
                .concat(
                    domandeColumns(globalState)
                )
            )
    }, [globalState.dataStructures, globalState.questions, activeForm])

    const handleSaveConfiguration = async (newModel) => {
        const user = await auth.currentUser
        //console.log("user:",user.uid)
        setTimeout(function () {
            ColumnsService.saveDefaultVisibilityModel(activeForm?.id, user.uid, newModel)
                .catch((err) => {
                    console.log("err:", err)
                    //setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
                .finally(() => setColumnVisibilityModel(newModel))
        }, 200);
    }

    const [error, setError] = useState(null)

    const handleCloseError = () => {
        setError(null)
    }

    const [graphMode, setGraphMode] = useState(false);

    const handleGraphMode = (event, newMode) => {
        if (newMode !== null)
            setGraphMode(newMode);
    };

    const handleAddParticipant = () => {
        setAddUserDialogState({...addUserDialogState, open: true})
    }

    const handleCloseAddUserDialog = (res) => {
        if (res?.status === 'success') {
            fetchUsers().then(() => {
                if (res?.userId)
                    navigate('/iscrizioni/user/' + res?.userId)
            })
        }
        setAddUserDialogState({...addUserDialogState, open: false})
    }

    /** SELEZIONE MULTIPLA */
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    useEffect(() => {
        if (!checkboxSelection)
            setRowSelectionModel([])
    }, [checkboxSelection])

    return (
        <div>
            <CustomBackdrop open={loadingUsers} children={false}/>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error"
                       sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            <Box>
                <Box mb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <CustomPageHeader title={'Elenco Iscrizioni'} //subtitle={'Elenco delle iscrizioni all\'evento'}
                                          refresh={fetchUsers}/>
                        {/*globalState?.authState?.isAdmin &&
                            <CustomTooltip title={"Mostra tutti gli utenti caricati"}
                                           children={
                                               <Stack alignItems={'center'}>
                                                   <Switch
                                                       size={'small'}
                                                       checked={allUsersFlag}
                                                       onChange={() => setAllUsersFlag(!allUsersFlag)}
                                                       inputProps={{'aria-label': 'controlled'}}
                                                   />
                                                   <AllInclusive fontSize={'small'}/>
                                               </Stack>}
                            />*/}
                        {!readOnly &&
                            <CustomTooltip
                                title={'Aggiungi nuovo partecipante'}
                                children={
                                    <Button variant={'outlined'} color={'accent'}
                                            size={'small'}
                                            sx={{ml: 1}}
                                            disabled={!activeForm?.main}
                                            startIcon={<PersonAdd/>}
                                            onClick={handleAddParticipant}>
                                        Aggiungi
                                    </Button>
                                }
                            />}
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <CustomToggleGraph graphMode={graphMode} handleGraphMode={handleGraphMode}/>
                    </Box>
                </Box>
                <div style={{display: 'flex',}}>
                    <div style={{flexGrow: 1}}>
                        <Tabs variant={'standard'} value={activeForm?.position} onChange={handleChangeActiveTab}
                              aria-label="forms tabs"
                              sx={{overflow: "visible", "& .MuiTabs-scroller": {overflow: "visible !important"}}}>
                            {
                                _forms && _forms?.map((tab) => {
                                    if (tab.id === forms.CHECKIN.id)
                                        return <Tab key={tab.id}
                                                    sx={{
                                                        background: alpha(customTheme.palette.primary.main, 0.1),
                                                        borderRadius: '8px 8px 0 0',
                                                        marginLeft: 'auto',
                                                    }}
                                                    label={<Stack direction={'row'} alignItems={'center'}>
                                                        {`${tab.label}`}
                                                        <Chip
                                                            label={`${_.filter(usersPerForm[tab.id], ['checkin', true])?.length} / ${usersPerForm[tab.id]?.length}`}
                                                            size={'small'}
                                                            color={'primary'}
                                                            sx={{marginLeft: 1,}}
                                                        />
                                                    </Stack>}/>
                                    if (tab.id === 'dataRichiesta')
                                        return <Tab key={tab.id}
                                                    sx={{
                                                        background: tab.id === activeForm?.id ?
                                                            alpha(customTheme.palette.accent.main, 0.3)
                                                            : alpha(customTheme.palette.accent.main, 0.1),
                                                        borderRadius: '8px 8px 0 0',
                                                        marginRight: 1
                                                    }}
                                                    label={<Stack direction={'row'} alignItems={'center'}>
                                                        {`${tab.label}`}
                                                        {<Chip label={filter(usersPerForm[tab.id], function (o) {
                                                            return o.esitoRichiesta === 'processing' //|| !!o[tab.timelineField]
                                                        }).length || 0}
                                                               size={'small'}
                                                               sx={{
                                                                   marginLeft: 1,
                                                                   background: customTheme.palette.accent.main,
                                                                   color: 'white'
                                                               }}
                                                        />}
                                                    </Stack>}
                                        />
                                    return <Tab key={tab.id}
                                                sx={{
                                                    background: tab.id === activeForm?.id ?
                                                        alpha(customTheme.palette.accent.main, 0.3)
                                                        : alpha(customTheme.palette.accent.main, 0.1),
                                                    borderRadius: '8px 8px 0 0',
                                                    marginRight: 1
                                                }}
                                                label={<Stack direction={'row'} alignItems={'center'}>
                                                    {`${tab.label}`}
                                                    {/*<Chip label={filter(usersPerForm[tab.id], function (o) {
                                                            return !!o[tab.id] //|| !!o[tab.timelineField]
                                                        }).length || 0}
                                                              size={'small'}
                                                              sx={{
                                                                  marginLeft: 1,
                                                                  background: customTheme.palette.accent.main,
                                                                  color: 'white'
                                                              }}
                                                        />*/}
                                                </Stack>}
                                    />
                                })
                            }
                            <Tab sx={{
                                background: forms.ARCHIVED.id === activeForm?.id ?
                                    alpha(customTheme.palette.accent.main, 0.2) : '',
                                borderRadius: '8px 8px 0 0',
                                marginLeft: globalState.settings?.checkin ? 'inherit' : 'auto',
                            }}
                                 disabled={readOnly}
                                 label={<Archive color={!readOnly ? 'primary' : 'disabled'}/>}
                            />
                            <ArchivedUsersDialog openArchivedDialog={openArchivedDialog}
                                                 setOpenArchivedDialog={setOpenArchivedDialog}
                                                 refetch={fetchUsers}
                            />
                        </Tabs>
                        {
                            reports && !isEmpty(reports) &&
                            <Grow in={reports && !isEmpty(reports)}>
                                <Stack p={0.5} direction={'row'} spacing={0.5} alignItems={'center'}
                                       flexWrap={'wrap'}
                                       sx={{
                                           background: activeForm.id === forms.CHECKIN.id ?
                                               alpha(customTheme.palette.primary.main, 0.1)
                                               : alpha(customTheme.palette.accent.main, 0.1)
                                       }}>
                                    {
                                        Object.entries(reports || []).map(([key, value], i) => {
                                            return <Chip key={i}
                                                         size={'small'} //color={'primary'} variant={'accent-outlined'}
                                                         label={`${key}: ${value || 0}`}/>
                                        })
                                    }
                                    {activeForm.id === forms.CHECKIN.id &&
                                        <QrCodeAppCheckin size={'small'} color={'accent'}/>}
                                    {activeForm.id === forms.CHECKIN.id &&
                                        <CustomTooltip title={'Vai alla pagina delle statistiche'} children={<IconButton size={'small'} onClick={() => {
                                            window.open(`${window.location.origin.toString()}/checkin/stats`, '_blank');
                                        }}>
                                            <QueryStats size={'small'} color={'accent'}/>
                                        </IconButton>}/>}
                                </Stack>
                            </Grow>
                        }
                        {!graphMode ?
                            <Box sx={{ width: "100%", height: 670, display: "grid" }}>
                                <DataGridPro
                                    //sx={{display: 'grid', gridTemplateRows: 'auto 1f auto'}}
                                    //autoHeight
                                    //getRowId={(rows) => rows.id}
                                    initialState={{
                                        pinnedColumns: {
                                            left: ['nome', 'cognome'],
                                        }
                                    }}
                                    localeText={{
                                        ...itIT.components.MuiDataGrid.defaultProps.localeText,
                                        toolbarExport: "Esporta colonne visibili"
                                    }}
                                    loading={loadingUsers || loadingConfig}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        handleSaveConfiguration(newModel)
                                    }
                                    onRowClick={(params, event, details) => {
                                        if (!checkboxSelection)
                                            navigate("/iscrizioni/user/" + params.row.id, {state: {activeFormId: activeForm?.id}});
                                    }}
                                    //density={'compact'}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {debounceMs: 500},
                                        },
                                    }}
                                    components={{
                                        ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
                                        Toolbar: () => <CustomGridToolbar buttons={!readOnly ? [
                                            <MultipleSelection
                                                selectedForm={activeForm}
                                                checkboxSelection={checkboxSelection}
                                                setCheckboxSelection={setCheckboxSelection}
                                                rowSelectionModel={rowSelectionModel}
                                                setRowSelectionModel={setRowSelectionModel}
                                                refetchUsers={fetchUsers}
                                                //openConfirmDialog={openConfirmDialog}
                                                //handleToggleConfirmDialog={handleToggleConfirmDialog}
                                            />] : null}/>,
                                        LoadingOverlay: LinearProgress,
                                    }}
                                    pagination
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    columns={!loadingConfig ? (columns || []) : []}
                                    rows={filteredUsers || []}
                                    //checkboxSelection={true}
                                    disableSelectionOnClick={!checkboxSelection}
                                    checkboxSelection={checkboxSelection}
                                    selectionModel={rowSelectionModel}
                                    onSelectionModelChange={(newRowSelectionModel) => {
                                        //console.log("newRowSelectionModel:",newRowSelectionModel)
                                        setRowSelectionModel(newRowSelectionModel);
                                    }}
                                />
                            </Box>
                            : <CustomDashboardGraph
                                utenti={filteredUsers}
                                activeForm={activeForm}/>
                        }
                    </div>
                </div>
                <Fab onClick={fetchUsers}
                     color={'primary'}
                     size={"medium"}
                     variant={'action'}>
                    <Refresh/>
                </Fab>
            </Box>
            <AddUserDialog
                dialogState={addUserDialogState}
                handleClose={handleCloseAddUserDialog}
                formId={activeForm?.id !== forms.CHECKIN.id ? activeForm?.id : (find(dataStructures, ['main', true])?.id || '')}
                setError={setError}/>
        </div>
    )
}

export default Iscrizioni
