import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import {FormControl, IconButton, InputLabel, Select} from "@mui/material";
import useAxios from "axios-hooks";
import SettingsService from "../../services/settings.service";
import MenuItem from "@mui/material/MenuItem";
import {GlobalContext} from "../../state/global";
import {setCheckinEvent} from "../../state/global/globalActions";

export const events = [
    {
        id: "checkedInAt",
        labelShort: "CHECKIN",
        label: "CHECK-IN - ACCREDITO"
    },
    {
        id: "ritiroFelpa",
        labelShort: "RITIRO FELPA",
        label: "RITIRO FELPA"
    },
    {
        id: "ritiroFoto",
        labelShort: "FOTO SCATTATA",
        label: "FOTO SCATTATA"
    }
]

export const EventSelection = ({fullWidth = false}) => {
    const [globalState, dispatch] = useContext(GlobalContext)
    console.log("globalState?.checkinEvent:",globalState?.checkinEvent)
    const [event, setEvent] = useState(globalState?.checkinEvent ?? events[0])

    const handleChangeEvent = (event) => {
        dispatch(setCheckinEvent(event.target.value))
        setEvent(event.target.value);
    };

    useEffect(() => {
        if(!!globalState?.checkinEvent) {
            setEvent(globalState.checkinEvent)
        }
    }, [globalState.checkinEvent])

    return (<FormControl fullWidth={fullWidth} sx={{marginX: 1, mt:1}}>
        <InputLabel id="select-label">Seleziona l'evento</InputLabel>
        <Select
            labelId="select-label"
            id="demo-simple-select"
            value={event}
            label="Seleziona l'evento"
            onChange={handleChangeEvent}
            sx={{background: 'white', minWidth: '100px'}}
        >
            {events.map((event) =>
                <MenuItem key={event.id}
                          value={event.id}
                >
                    {event.label}
                </MenuItem>)}
        </Select>
    </FormControl>);
}

export default function CheckinAppBar({handleDialogOpen}) {
    const [{data: dataSettings, loading, error}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color={'secondary'}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Check in
                    </Typography>
                    <EventSelection/>
                    <Typography variant={'body1'} textAlign="center">
                        {dataSettings?.settings?.eventName || ''}
                    </Typography>
                    {<IconButton
                        size="large"
                        aria-label="cerca iscritto"
                        color="inherit"
                        onClick={handleDialogOpen}
                    >
                        <PersonSearchIcon />
                    </IconButton>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
