import {CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import './App.css';
import {customTheme} from "./theme/customTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Iscrizioni from "./pages/iscritti/Iscrizioni";
import axios from "axios";
import Home from "./pages/Home";
import Settings from "./pages/settings/Settings";
import {GlobalContext} from "./state/global";
import {useContext, useEffect, useMemo, useReducer, useState} from "react";
import Logger from "./state/Logger";
import {globalReducer, initializeState} from "./state/global/globalReducer";
import UserDetails from "./pages/iscritti/UserDetails";
import CheckIn from "./pages/CheckIn";
import {auth} from "./firebase/clientApp";
import jwt_decode from "jwt-decode";
import {setAuthState} from "./state/global/globalActions";
import CheckInStats from "./pages/CheckInStats";

const AppUI = () => {
    document.body.style.backgroundColor = '#FFFFFF';
    const [globalState, dispatch] = useContext(GlobalContext)

    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            //console.log("user:", user)
            const token = await user?.getIdToken()

            if (token) {
                /** L'utente deve contenere nei claim il campo "role" (ADMIN | AGENCY) */
                const role = jwt_decode(token)?.role
                //console.log("role:", role)
                //console.log("user.uid:", user.uid)
                if(!!role) {
                    dispatch(setAuthState({isAdmin: role === 'ADMIN', role: role, uid: user.uid}))
                    setUser(user)
                } else {
                    navigate('/login', {replace: true})
                }
            } else {
                navigate('/login', {replace: true})
            }
        })
    },[])

    const readOnly = useMemo(() => {
        if(globalState?.authState?.role === 'CLIENT') {
            return true
        } else return false
    }, [globalState?.authState]);

    return (<>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                {user && <Route element={<ProtectedRoute user={user}/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/iscrizioni'} element={<Iscrizioni readOnly={readOnly}/>}/>
                    <Route path={'/iscrizioni/user/:id'} element={<UserDetails readOnly={readOnly}/>}/>
                    {!readOnly && <Route path={'/settings'} element={<Settings/>}/>}
                </Route>}
            </Routes>
    </>)
}

const App = () => {
    console.log("Developed by\n" +
        "\n" +
        "______ _           _                \n" +
        "| ___ \\ |         | |               \n" +
        "| |_/ / | ___  ___| |__    ___ ___  \n" +
        "|  __/| |/ _ \\/ __| '_ \\  / __/ _ \\ \n" +
        "| |   | |  __/\\__ \\ | | || (_| (_) |\n" +
        "\\_|   |_|\\___||___/_| |_(_)___\\___/ \n" +
        "                                    \n" +
        "                                    \n")

    const currentLocation = useLocation().pathname

    axios.interceptors.request.use(
        async config => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    /*
    // per gestire la redirect 401 unauthorized e far sloggare utente
    axios.interceptors.response.use(response => {
        console.log("response",response)
        return response;
    }, function (error) {
        console.log("error 401:",error.toString().includes('401'))
        if (error.toString().includes('401')) {
            //place your reentry code
            window.location.replace(`${window.location.origin.toString()}/login`);
        }
        return error;
    });*/

    return (
        <GlobalContext.Provider
            value={useReducer(Logger(globalReducer), {}, () => initializeState({currentLocation: currentLocation}))}
            displayName="Global Context">
            <ThemeProvider theme={customTheme}>
                <CssBaseline/>
                <Routes>
                    <Route path={'/checkin/stats'} element={<CheckInStats/>}/>
                    <Route path={'/checkin'} element={<CheckIn/>}/>
                    <Route path={'/*'} element={<AppUI/>}/>
                </Routes>
            </ThemeProvider>
        </GlobalContext.Provider>
    );
}

export default App;
