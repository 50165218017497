import React, {useMemo} from "react";
import { ResponsiveLine } from '@nivo/line'
import {Typography} from "@mui/material";
import {each, orderBy} from "lodash";

const commonProperties = {
    width: 900,
    height: 400,

    enableSlices: 'x',
}

const CustomTimelineChart = ({utenti, enableArea = false, timelineField, minutes = false}) => {
    //console.log("timelineField:",timelineField)
    //console.log("utenti:",utenti)
    const parsedData = useMemo(() => {
        if(!utenti)
            return null

        let res
        const accumulator = {}

        each(utenti, (user) => {
            if(user && user[timelineField]) {
                const date = new Date((user[timelineField]?._seconds * 1000))
                //console.log("date:",date)
                const dateKey = minutes ?
                    `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}, ${date.getHours()}:${date.getMinutes()}`
                    : `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
                //console.log("dateKey:",dateKey)

                if(!accumulator[dateKey]) {
                    accumulator[dateKey] = 1
                } else {
                    accumulator[dateKey]++
                }
            }
        })
        //console.log("accumulator:",accumulator)

        res = Object.entries(accumulator).map(([key, counter], i) => {
            return {
                x: key,
                y: counter,
            }
        })

        //console.log("res:",res)
        //console.log("orderBy(res, ['x']):",orderBy(res, ['x']))
        return [{id: 'iscrizioni', data: res}]
        //return [{id: 'iscrizioni', data: orderBy(res, ['x'])}]
    }, [utenti])

    return (
        parsedData ? <ResponsiveLine
            margin={{top: 40, right: 55, bottom: 55, left: 50}}
            animate={false}
            data={parsedData}
            enableArea={enableArea}
            xScale={{
                type: 'time',
                format: minutes ? '%Y-%m-%d, %H:%M' : '%Y-%m-%d',
                useUTC: false,
                precision: minutes ? 'minute' : 'day',
            }}
            xFormat={minutes ? "time:%d/%m/%Y %H:%M" : "time:%d/%m/%Y"}
            yScale={{
                type: 'linear',
                stacked: false,
            }}
            yFormat=" >-.0f"
            axisLeft={{
                legend: 'Numero iscritti',
                legendOffset: 12,
            }}
            axisBottom={{
                format: minutes ? '%H:%M, %d/%m' : '%d/%m/%Y',//'%b %d',
                tickValues: minutes ? 'every 1 minute' : 'every 7 day',
                tickRotation: 35,
                legend: '',
                legendOffset: -12,
            }}
            curve={'monotoneX'}
            enablePointLabel={true}
            pointSymbol={CustomSymbol}
            pointSize={16}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={false}
        /> : <Typography textAlign={'center'}>Dati insufficienti</Typography>

    )
}

export default CustomTimelineChart

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
        <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
)