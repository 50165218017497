import React, {useContext, useMemo} from "react";
import {Box, Fab, Paper, Typography} from "@mui/material";
import CustomTimelineChart from "../components/charts/CustomTimelineChart";
import useAxios from "axios-hooks";
import UserService from "../services/user.service";
import {filter, find, orderBy} from "lodash";
import CustomBackdrop from "../components/CustomBackdrop";
import CustomPageHeader from "../components/CustomPageHeader";
import {Refresh} from "@mui/icons-material";
import {GlobalContext} from "../state/global";

const Home = () => {
    const [globalState] = useContext(GlobalContext)

    const [{data: allUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({all: true}), { method: "GET", useCache: false, manual: false }
    )

    const mainFormId = useMemo(() => {
        return find(globalState.dataStructures, ['main', true])?.id || ''
    }, [globalState.dataStructures])

    const users = useMemo(() => {
        if(!allUsers || !mainFormId)
            return null
        else return orderBy(allUsers, mainFormId+'._seconds', 'desc')
    }, [allUsers, mainFormId])

    return (
        <div>
            <CustomBackdrop open={loadingUsers}/>
            <CustomPageHeader title={'Andamento iscrizioni'} subtitle={'Descrizione grafico e overview'} refresh={fetchUsers}/>
            <Box px={2} py={1} my={1} display={'flex'} alignItems={'center'} component={Paper} variant={'outlined'}
                  textAlign={'left'}>
                <Box flex={1}>
                    <Typography>Utenti invitati totali</Typography>
                    <Typography variant={'h4'}>{users?.length || '-'}</Typography>
                </Box>
                <Box flex={1} justifyContent={'start'}>
                    <Typography>Risposte totali</Typography>
                    <Typography variant={'h4'}>{filter(users, function (o) {
                        //TODO: dinamizzare 'Non parteciperò'
                        return !!o.partecipazione
                    })?.length || '-'}</Typography>
                </Box>
                <Box flex={1} justifyContent={'start'}>
                    <Typography>Utenti partecipanti</Typography>
                    <Typography variant={'h4'}>{filter(users, function (o) {
                        //TODO: dinamizzare 'Non parteciperò'
                        return !!o.partecipazione && o.partecipazione !== 'nonPartecipo'
                    })?.length || '-'}</Typography>
                </Box>
                <Box flex={1} justifyContent={'start'}>
                    <Typography>Utenti NON partecipanti</Typography>
                    <Typography variant={'h4'}>{filter(users, function (o) {
                        //TODO: dinamizzare 'Non parteciperò'
                        return !!o.partecipazione && o.partecipazione === 'nonPartecipo'
                    })?.length || '-'}</Typography>
                </Box>
                <Box flex={1}>
                    <Typography>Percentuale iscrizione</Typography>
                    <Typography variant={'h4'}>
                        {((((filter(users, function (o) {
                            return !!o.partecipazione && o.partecipazione !== 'nonPartecipo'
                        })?.length) * 100) / users?.length) || 0).toPrecision(3) || '-'} %
                    </Typography>
                </Box>
            </Box>
            <Box mt={1} p={2} component={Paper} variant={'outlined'} height={'450px'}>
                <CustomTimelineChart utenti={users} enableArea={true} timelineField={mainFormId || ''}/>
            </Box>
            <Fab onClick={fetchUsers}
                 color={'primary'}
                 size={"medium"}
                 variant={'action'}>
                <Refresh/>
            </Fab>
        </div>
    )
}

export default Home