import logo from "../assets/images/logo_bicolor_1000px/Logo winston - rosso bianco@1K.png"
import * as React from "react";

const CustomLogo = ({width, height, bw = true}) => {
    return (
        <img src={logo} width={width} height={height}
             style={{
                 display: { xs: 'none', md: 'flex' },
                 objectFit: 'contain',
                 margin: '1rem 1rem',
                 filter: bw ? 'brightness(0) invert(1)' : ''
        }}/>
    )
}

export default CustomLogo