import {
    SET_DATA_STRUCTURE,
    SET_DATA_STRUCTURES,
    SET_SELECTED_ROUTE,
    SET_SETTINGS,
    SET_AUTH_STATE,
    SET_QUESTIONS,
    SET_CHECKIN_EVENT
} from "./globalActions";

export const initializeState = ({currentLocation}) => {
    return {
        selectedRoute: currentLocation,
        settings: null,
        dataStructures: null
    };
}

export const globalReducer = (state, action) => {
    switch (action.type) {
        case SET_AUTH_STATE: {
            const {authState} = action;

            if (authState) {
                return {
                    ...state,
                    authState: authState
                };
            }
            return state;
        }

        case SET_SELECTED_ROUTE: {
            const {selectedRoute} = action;

            if (selectedRoute) {
                return {
                    ...state,
                    selectedRoute: selectedRoute
                };
            }
            return state;
        }

        case SET_SETTINGS: {
            const {settings} = action;

            if (settings) {
                return {
                    ...state,
                    settings: settings
                };
            }
            return state;
        }

        case SET_DATA_STRUCTURES: {
            const {dataStructures} = action;

            if (dataStructures) {
                return {
                    ...state,
                    dataStructures: dataStructures
                };
            }
            return state;
        }

        case SET_DATA_STRUCTURE: {
            const {dataStructure} = action;

            if (dataStructure) {
                return {
                    ...state,
                    dataStructures: {
                        ...state.dataStructures,
                        [dataStructure.id]: dataStructure
                    }
                };
            }
            return state;
        }

        case SET_QUESTIONS: {
            const {questionsAtt1, questionsAtt2} = action;
            if (questionsAtt1 && questionsAtt2) {
                return {
                    ...state,
                    questions: [
                        ...questionsAtt1
                    ],
                    questionsAtt2: [
                        ...questionsAtt2
                    ]
                };
            }
            return state;
        }

        case SET_CHECKIN_EVENT: {
            const {event} = action;

            if (event) {
                return {
                    ...state,
                    checkinEvent: event
                };
            }
            return state;
        }

        default:
            return state;
    }
}
