export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SET_SELECTED_ROUTE = "SET_SELECTED_ROUTE";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_DATA_STRUCTURES = "SET_DATA_STRUCTURES";
export const SET_DATA_STRUCTURE = "SET_DATA_STRUCTURE";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_CHECKIN_EVENT = "SET_CHECKIN_EVENT";


export const setAuthState = (authState) => ({type: SET_AUTH_STATE, authState})

export const setSelectedRoute = (selectedRoute) => ({type: SET_SELECTED_ROUTE, selectedRoute})

export const setGlobalSettings = (settings) => ({type: SET_SETTINGS, settings})

export const setDataStructures = (dataStructures) => ({type: SET_DATA_STRUCTURES, dataStructures})
export const setDataStructure = (dataStructure) => ({type: SET_DATA_STRUCTURE, dataStructure})

export const setQuestions = (questionsAtt1, questionsAtt2) => ({type: SET_QUESTIONS, questionsAtt1: questionsAtt1, questionsAtt2: questionsAtt2})

export const setCheckinEvent = (event) => ({type: SET_CHECKIN_EVENT, event})
