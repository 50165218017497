import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material";

export function InputRisposta({i, handleUpdateRisposta, handleRemoveRisposte, risp, optionLabel, startIndex}) {
    const [risposta, setRisposta] = useState('')

    useEffect(()=> setRisposta(risp), [risp])

    function handleSetRisposta (event) {
        setRisposta(event.target.value)
        let value = event.target.value
        handleUpdateRisposta({i, value})
    }

    return (
        <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={2} mt={2}>
                <IconButton onClick={() => handleRemoveRisposte({i})} variant={'secondary'}>
                    <RemoveCircle />
                </IconButton>
            </Grid>
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField value={risposta} onChange={handleSetRisposta} label={optionLabel + ' ' + (i + 1)} variant={"standard"} fullWidth/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function Risposte({handleSetRisposte, risp, title, optionLabel, startIndex}) {
    const [risposte, updateRisposte] = useState([])

    useEffect(() => {updateRisposte(risp)},[risp])

    function addNewRisposta() {
        let e = ''
        let r = risposte
        let newR = r.concat(e)
        handleSetRisposte(newR)
    }

    function handleUpdateRisposta({i,value}) {
        let r = risposte
        r[i] = value
        handleSetRisposte(r)
    }

    function handleRemoveRisposte({i}) {
        let r = risposte
        r.splice(i,1)
        handleSetRisposte(r)
    }

    return (
        <Box sx={{pt: 1}}>
            <Typography variant={"body1"}>{title}</Typography>
            <Stack direction={"column"}>
                {risposte?.map((r,i) =>
                    <InputRisposta
                        key={i}
                        i={i}
                        risp={risposte[i]}
                        optionLabel={optionLabel}
                        handleUpdateRisposta={handleUpdateRisposta}
                        handleRemoveRisposte={handleRemoveRisposte}
                        startIndex={startIndex}
                    />
                )}
            </Stack>
            <IconButton onClick={addNewRisposta} variant={'secondary'}>
                <AddCircle/>
            </IconButton>
        </Box>
    )
}