import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import {Button, Dialog, DialogActions, DialogContent, Stack} from "@mui/material";
import {ContentCopy, Download} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const ImgPopUpComponent = ({filename, imgUrl, maxWidthPreview = '30px', maxWidthImg = '200px' }) => {
    const [open, setOpen] = useState(false)

    function handleToggleDialog() {
        setOpen(!open)
    }

    function downloadImage(url) {
        const img = new Image();
        img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
        // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
        img.src = url;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            const a = document.createElement('a');
            a.download = `${filename}`;
            a.href = canvas.toDataURL('image/*');
            a.click();
        };
    }

    return (
        <>
            <CustomTooltip title={'Apri immagine'} children={
                <Button size={'small'}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDialog()
                        }}>
                    <img src={imgUrl} style={{maxWidth: maxWidthPreview}} onClick={() => {}}/>
                </Button>
            }/>
            <Stack direction={'row'} alignItems={'center'}>
                <CustomTooltip title={"Copia link all'immagine"} children={
                    <IconButton
                        onClick={async (event) => {
                            event.stopPropagation()
                            await navigator.clipboard.writeText(imgUrl)
                                .then(() => window.alert("Link copiato!"))
                        }}>
                        <ContentCopy fontSize={'small'}/>
                    </IconButton>
                }/>
                <CustomTooltip title={"Scarica l'immagine"} children={
                    <IconButton
                        onClick={async (event) => {
                            event.stopPropagation()
                            downloadImage(imgUrl)
                        }}>
                        <Download fontSize={'small'}/>
                    </IconButton>
                }/>
            </Stack>
            <Dialog open={open} onClose={handleToggleDialog} maxWidth={'md'}>
                <DialogContent>
                    <img src={imgUrl} style={{width: maxWidthImg}}/>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<ContentCopy/>}
                        onClick={async (event) => {
                            event.stopPropagation()
                            await navigator.clipboard.writeText(imgUrl)
                                .then(() => window.alert("Link copiato!"))
                        }}>
                        COPIA LINK
                    </Button>
                    <Button variant={'contained'} startIcon={<Download/>} onClick={() => downloadImage(imgUrl)}>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ImgPopUpComponent