import React from "react";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {orderBy, remove} from "lodash";

export const forms = {
    CHECKIN: {id: "checkin", label: "Check-in", position: 100, timelineField: 'checkedInAt', precisionMinute: true, description: 'Struttura che consente di monitorare l\'accesso all\'evento'},
    ARCHIVED: {id: "archived", label: "", position: 101, timelineField: 'updatedAt', precisionMinute: false, description: 'Utenti archiviati'},
}

const settingsUrl = () => {
    return `${API_URL}config/settings`
}

const getSettings = () => {
    return axios.get(settingsUrl(), { headers: authHeader() })
};

const saveSettings = (settings) => {
    return axios.post(settingsUrl(), {...settings}, {headers: authHeader()})
};

const getForms = (settings, dataStructures) => {
    const _data = Object.values(dataStructures)
    const res = orderBy(_data, 'position')
    if(!settings?.checkin) {
        remove(res, ['id', forms.CHECKIN.id])
    } else {
        dataStructures[forms.CHECKIN.id] = {
            ...dataStructures[forms.CHECKIN.id],
            position: _data.length -1
        }
    }

    return res
};

const SettingsService = {
    settingsUrl,
    getSettings,
    saveSettings,
    getForms
};

export default SettingsService;