import {alpha, styled, Switch} from "@mui/material";
import {customTheme} from "../theme/customTheme";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: customTheme.palette.accent.main,
        '&:hover': {
            backgroundColor: alpha(customTheme.palette.accent.main, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: customTheme.palette.accent.main,
    },
}));

export default CustomSwitch