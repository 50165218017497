import React from "react";
import {API_URL} from "../config";

const dataStructuresUrlV2 = (id) => {
    return `${API_URL}data-structures${id ? ('/'+id) : ''}`
}

const DataStructuresService = {
    dataStructuresUrlV2,
};

export default DataStructuresService;