import {createTheme} from "@mui/material";
import {itIT} from '@mui/material/locale';

export const APP_BAR_HEIGHT = 60;

export const customTheme = createTheme({
    typography: {
        fontFamily: 'Neue Montreal Regular',
        h1: {fontFamily: 'Neue Montreal Medium'},
        h2: {fontFamily: 'Neue Montreal Medium'},
        h3: {fontFamily: 'Neue Montreal Medium'},
        h4: {fontFamily: 'Neue Montreal Medium'},
        h5: {fontFamily: 'Neue Montreal Medium'},
        h6: {fontFamily: 'Neue Montreal Medium'},
        button: {fontFamily: 'Neue Montreal Medium', fontSize: '16'},
    },

    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    ":disabled": {
                        color: "lightGray",
                        textDecoration: "none",
                        cursor: "default",
                        "&:hover": {
                            textDecoration: "none"
                        }
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: APP_BAR_HEIGHT,
                    borderRadius: 0
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "rgba(249,35,59,1)",
                    '&:disabled': {
                        color: "rgba(249,35,59,0.3)",
                    }
                }
            },
            variants: [
                {
                    props: {variant: 'secondary'},
                    style: {
                        color: "#30424b",
                        '&:disabled': {
                            color: "rgba(48,66,75,0.4)",
                        }
                    }
                },
            ]
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'menu'},
                    style: {
                        color: 'white',
                        paddingTop: 2,
                        paddingBottom: 2,
                    }
                },
                {
                    props: {variant: 'menu-selected'},
                    style: {
                        color: 'white',
                        backgroundColor: "#F9233B",
                        paddingTop: 2,
                        paddingBottom: 2,
                    }
                },
                {
                    props: {variant: 'submit'},
                    style: {
                        color: 'white',
                        backgroundColor: "#F9233B",
                        paddingInline: '1.5rem',
                        borderRadius: '1rem',
                        boxShadow: '1px 1px 6px 0px rgba(48,66,75,0.45)',
                        '&:disabled': {
                            backgroundColor: "rgba(249,35,59,0.15)",
                            boxShadow: 'none',
                        }
                    }
                }
            ]
        },
        MuiFab: {
            variants: [{
                props: {variant: 'action'},
                style: {
                    top: 'auto',
                    right: '20px',
                    bottom: '40px',
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1010,
                    backgroundColor: '#F9233B'
                }
            }]
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '1.1rem',
                    //boxShadow: '1px 1px 6px 0px rgb(140 144 146 / 15%)'
                },
            },
            variants: [
                {
                    props: {variant: 'login'},
                    style: {
                        width: '100%',
                        maxWidth: '37.5rem',
                        margin: '2rem auto',
                        height: '100%',
                        borderRadius: '2rem',
                        border: 0,
                        padding: '1rem 2rem 3rem 2rem',
                        backgroundColor: 'rgba(255,255,255,0.0)',
                    },
                },
            ]
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    background: 'rgba(48,66,75,0.95)',
                    color: 'white',
                    marginBottom: '1rem',
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    //border: '3px solid #30424b',
                    borderRadius: '1.1rem',
                    boxShadow: '0px 0px 18px 0px rgba(48,66,75,0.45)'
                },
            },
            variants: [
                {
                    props: {variant: 'checkin'},
                    style: {
                        width: '100%',
                        top: '140px',
                    },
                },
            ]
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    width: '100% !important'
                }
            }
        },
        MuiChip: {
            variants: [
                {
                    props: {variant: 'accent-outlined'},
                    style: {
                        border: `1px solid #F9233B`,
                        backgroundColor: `transparent !important`,
                        color: `#F9233B !important`,
                        fontWeight: 'bold'
                    },
                },
            ]
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#30424b',
        },
        secondary: {
            main: '#252a33',
        },
        /*background: {
            default: '#252a33',
            paper: '#252a33',
        },*/
        accent: {
            main: '#F9233B',
            secondary: '#e6fc69',
        },
        text: {
            primary: '#263238',
        },
        error: {
            main: '#c58735'
        }
    },
    itIT,
});
