import React from "react";
import _, {filter, find, includes, map, orderBy, split} from 'lodash'
import {fieldTypes, getColorFromCategoria, parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, Chip, darken, Divider, Grid, Stack, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import {BlurOn, Check, Close, ContentCopy, FiberManualRecord, FileOpen, Link, OpenInNew} from "@mui/icons-material";
import ImgPopUpComponent from "../components/ImgPopUpComponent";
import {customTheme} from "../theme/customTheme";
import FormCompletedCustomChip from "../pages/iscritti/FormCompletedCustomChip";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId||''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns}, )
};

const saveDefaultVisibilityModel = (formId, userId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {[userId]: {defaultVisibilityModel: columns}}}, )
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.label})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form, questions, questionsAtt2, refetch) => {
    const temp = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return o.type !== 'note' && !o?.noDataGrid && !field?.noDataGrid && !field?.onlyUserForm
    })
    const cols = temp?.map((field) => {
        function getValue(field, params) {
            const id = (includes(field.id, '.')) ? split(field.id, '.')[1] : field.id
            return field?.getter ? params.row[field.getter]?.[id] : params.row[id]
        }

        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
            minWidth: 100,
            type: (field.type === 'select' || field.type === 'radiogroup') ? 'singleSelect' : field.type,
            values: field.values,
            valueOptions: field.values?.map((opt) => (opt.label))
        }

        baseField = field?.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }
        } : {
            ...baseField, valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }
        }

        const formType = _.find(fieldTypes, ['type', field.type])
        if(!formType?.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }

                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }

                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toString() !== 'Invalid Date' ?
                                        new Date(params.value).toLocaleTimeString('it-IT')
                                        : params.value
                                    : "");
                        },
                    }

                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì') {
                                return 'si';
                            } else if (getValue(field, params) === false || getValue(field, params) === 'false' || !getValue(field, params) || getValue(field, params) === 'no')
                                return 'no';
                            else return getValue(field, params) || '-'
                        },
                        valueFormatter: (params) => {
                            /** e.g. */ //const partecipazione = params.api.getRow(params.id)?.partecipazione
                            if (params.value === true || params.value === 'true' || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'false' || params.value === 'no' || !params.value)
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (getValue(field, params) === false || getValue(field, params) === 'false' || getValue(field, params) === 'no' || getValue(field, params) === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }

                case fieldTypes.URL:
                    return {
                        ...baseField,
                        width: 25,
                        flex: 0,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                {/*<CustomTooltip title={"Copia link"}
                                    children={<IconButton size={'small'} color={'primary'}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>}
                                />*/}
                                <CustomTooltip
                                    title={'Apri link in un\'altra finestra'}
                                    children={
                                        <IconButton size={'small'} color={'primary'}
                                                    href={params.value}
                                                    target={'_blank'}
                                                    onClick={(event) => event.stopPropagation()}>
                                            <OpenInNew fontSize={'small'}/>
                                        </IconButton>}/>
                            </Box> : ""
                    }

                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        disableExport: true,
                        renderCell: (params) => {
                            if (params.row.partecipazione === 'online')
                                return <CustomTooltip title={"Copia link streaming"} children={
                                    <Button
                                        color={'accent'}
                                        onClick={async (event) => {
                                            event.stopPropagation()
                                            await navigator.clipboard.writeText(`https://meetwinston.app/live/${params.id}`)
                                        }}>
                                        <Link/>
                                    </Button>
                                }/>
                            else if (params.row.partecipazione === 'partecipo')
                                return !!params.row.qrCodeUrl ?
                                    <ImgPopUpComponent filename={`QR-${params.row.nome||''}-${params.row.cognome||''}-${params.id}`} imgUrl={params.row.qrCodeUrl}/>
                                    : <Chip label={'No QR Code'} size={'small'}/>
                            else if (params.row.partecipazione === 'nonPartecipo')
                                return ''
                        }
                    }

                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna delle precedenti" ? label : params.value
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'partecipo') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'nonPartecipo')
                                return 'No';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        renderCell: (params) => {
                            return !!params?.value ? (params?.value === true
                                || params?.value?.toLowerCase() === 'si' || params?.value?.toLowerCase() === 'sì'
                                || params?.value === 'partecipo')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params?.value === false || params?.value?.toLowerCase() === 'no' || params?.value === 'nonPartecipo')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params?.value])?.label || params?.value || '-') : null
                        }
                    }

                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna" ? label : params.value
                        },
                    }

                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }

                case fieldTypes.COLOR:
                    return {
                        ...baseField,
                        /*valueGetter: (params) => {
                            return getLabelFromColor(params.value)
                        },*/
                        renderCell: (params) => {
                            return (params?.value || getColorFromCategoria(params?.value)) ?
                                <Stack>
                                    <Chip sx={{
                                        backgroundColor: params?.value || getColorFromCategoria(params?.value),
                                        fontWeight:'bold',
                                        color: customTheme.palette.getContrastText(params?.value || getColorFromCategoria(params?.value)),
                                        border: `2px solid ${darken(params?.value || (getColorFromCategoria(params?.value)), 0.2)}`
                                    }}
                                          size={'small'}
                                          label={params?.value}
                                    />
                                </Stack>
                                : '-'//<DoDisturb color={'disabled'}/>
                        }
                    }

                case fieldTypes.FILE_UPLOAD:
                    return {
                        ...baseField,
                        renderCell: (params) => {
                            return params?.value ?
                                <CustomTooltip title={`Apri ${field.label}`}
                                               children={
                                                   <IconButton size={'small'}
                                                               color={"accent"}
                                                               variant={'outlined'}
                                                               href={params?.value}
                                                               target={'_blank'}
                                                               onClick={(event) => event.stopPropagation()}
                                                   >
                                                       <FileOpen/>
                                                   </IconButton>}
                                /> : '-'
                        }
                    }

                case fieldTypes.CUSTOM:
                    if(field.id === 'activity2') {
                        return {
                            ...baseField,
                            //hide: true,
                            valueGetter: (params) => {
                                return params.value;
                            },
                            renderCell: (params) => {
                                //console.log("activity2 > params:",params)
                                //const risposta = params?.value
                                //risposta.custom ? `${risposta?.custom}` : '-'
                                return <FormCompletedCustomChip completionField={params.row?.activity2CompletedAt}/>
                            },
                            valueFormatter: (params) => {
                                const risposta = params?.value
                                let res = ""

                                if(risposta && !!risposta.ids) {
                                    risposta.ids?.forEach((question) => {
                                        res += (question + ' - ' + questionsAtt2[question - 1]?.it + ' | ')
                                    })

                                    res += ('Fai la TUA Domanda: ' + risposta.custom)
                                }

                                return risposta ? parseMultiLineTextBox(res) : '-'
                            },
                        }
                    } else return {
                        ...baseField,
                        hide: true,
                        valueGetter: (params) => {
                            return params.row[params?.field];
                        },
                        renderCell: (params) => {
                            const risposta = params?.value
                            return <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
                                {(questions && risposta) ?
                                    <FiberManualRecord
                                        fontSize={'large'}
                                        sx={{color: questions ? questions[(risposta?.id) - 1]?.color : 'inherit',}}
                                    />
                                    : '-'
                                }
                                    {/*<Chip label={`ID: ${(params.row[params.field]?.id)-1}`} size={'small'} variant={'outlined'}
                                          sx={{background: questions ? questions[(params.row[params.field]?.id)-1]?.color : 'inherit', }}
                                    />*/}
                            </Stack>
                        },
                        valueFormatter: (params) => {
                            const risposta = params?.value

                            const domanda = questions[(risposta?.id) - 1]
                            return domanda ? parseMultiLineTextBox(`${domanda.id} - D: '${domanda?.it}' - R: '${risposta?.risposta}'`) : '-'
                        },
                    }

                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;

/** TODO: sistemare i commonFields */
export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]