import React, {useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import Header from "./appbar/Header";
import {APP_BAR_HEIGHT} from "../theme/customTheme";
import {Box, Typography} from "@mui/material";
import CustomLoader from "./CustomLoader";
import {GlobalContext} from "../state/global";
import useAxios from "axios-hooks";
import SettingsService from "../services/settings.service";
import DataStructuresService from "../services/dataStructures.service";
import {setDataStructures, setGlobalSettings, setQuestions} from "../state/global/globalActions";
import {API_URL} from "../config";
import {orderBy} from "lodash";

const ProtectedRoute = ({children, user}) => {
    //console.log("ProtectedRoute >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    const [globalState, dispatch] = useContext(GlobalContext)

    const [{data: dataSettings, loading: loadingSettings, error: errorSettings}] = useAxios(
        SettingsService.settingsUrl(), {method: "GET", useCache: false, manual: false}
    )

    const [{data: dataStructures, loading: loadingDataStructures, error: errorDataStructures}] = useAxios(
        DataStructuresService.dataStructuresUrlV2(), {method: "GET", useCache: false, manual: false}
    )

    const [{data: questions, loading: loadingQuestions, error: errorQuestions}] = useAxios(
        `${API_URL}questions`, {method: "GET", useCache: false, manual: false}
    )

    useEffect(() => {
        if (dataSettings) {
            dispatch(setGlobalSettings(dataSettings.settings))
        }
    }, [dataSettings])

    useEffect(() => {
        if (dataStructures) {
            dispatch(setDataStructures(dataStructures.data))
        }
    }, [dataStructures])

    useEffect(() => {
        if (questions) {
            dispatch(setQuestions(orderBy(questions.questionsAtt1, ['id', 'asc']), orderBy(questions.questionsAtt2, ['id', 'asc'])))
        }
    }, [questions])

    return <div className="App" style={{marginTop: APP_BAR_HEIGHT}}>
        {//globalState.settings && globalState.dataStructures &&
            user ?
                <Header children={
                    <Box m={2} px={3}>
                        {children ?
                            children : <Outlet/>}
                    </Box>}/>
            : <Typography>Finisci di fare il setup del progetto</Typography>
        }
        {(loadingSettings || loadingDataStructures) && <CustomLoader/>}
        {(errorSettings || errorDataStructures) && <Typography>Ops... qualcosa è andato storto</Typography>}
    </div>
};

export default ProtectedRoute
