import {Box, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";
import React from "react";

const CustomPageHeader = ({title, subtitle, refresh = null, gutterBottom = true}) => {
    return <div>
        <Box display={'flex'} alignItems={'center'}>
            <Typography gutterBottom={gutterBottom} variant={"h5"} textAlign={'left'}>{title}</Typography>
            {!!refresh && <IconButton onClick={refresh} color={'accent'} sx={{paddingTop: 0.75}}>
                <Refresh/>
            </IconButton>}
        </Box>
        <Typography variant={"subtitle2"} sx={{textAlign: 'left'}}>{subtitle}</Typography>
    </div>;
}

export default CustomPageHeader