import {Diversity1, LocalPolice, WorkspacePremium} from "@mui/icons-material";

// export const API_URL = "http://localhost:8080/";
export const API_URL = process.env.REACT_APP_API_URL;

export const appName = process.env.REACT_APP_APP_NAME || 'Dashboard'

export const roles = {
    ADMIN: {
        icon: <LocalPolice/>,
        label: "Admin",
        id: "ADMIN"
    },
    AGENCY: {
        icon: <Diversity1/>,
        label: "Agenzia",
        id: "AGENCY"
    },
    CLIENT: {
        icon: <WorkspacePremium/>,
        label: "Cliente",
        id: "CLIENT"
    }
}
